import React from 'react';
import { useEffectOnce } from 'react-use';
import { Route, Router, Switch, Redirect } from 'react-router-dom'; // Switch, Redirect
import { createBrowserHistory } from 'history';
import loadable from '@loadable/component';
import { connect } from 'react-redux';
import Main from '../Layouts/Main';
import { localeList } from '../../utils/Helper';
import store from '../../redux/store';
import { getSiteConfig } from '../../redux/modules/siteConfig/action';

import c from '../../config';

// Default Style
import '../../assets/scss/theme.scss';

// cookie
const CookieConsent = loadable(() => import('../../components/CookieConsent'));

// SSO
const SSOStatus = loadable(() => import('../../components/SSOStatus'));

// Routes
const HomePage = loadable(() => import('../../pages/HomePage'));
const IndexPage = loadable(() => import('../../pages/IndexPage'));
const StaticPage = loadable(() => import('../../pages/StaticPage'));
const ForumPage = loadable(() => import('../../pages/ForumPage'));
const MarketIntelligencePage = loadable(() => import('../../pages/MarketIntelligencePage'));
const ReportPage = loadable(() => import('../../pages/ReportPage'));
const MembershipBenefitsPage = loadable(() => import('../../pages/BenefitPage'));
const SpeakersPage = loadable(() => import('../../pages/SpeakersPage'));
const ProgrammePage = loadable(() => import('../../pages/ProgrammePage'));
const PhotoGalleryPage = loadable(() => import('../../pages/PhotoGalleryPage'));
const VideoGalleryPage = loadable(() => import('../../pages/VideoGalleryPage'));
const LinksPage = loadable(() => import('../../pages/LinksPage'));
const PressReleasePage = loadable(() => import('../../pages/PressReleasePage'));
const PromotionPage = loadable(() => import('../../pages/PromotionPage'));
const NoMatch = loadable(() => import('../../pages/NoMatch'));
const ComingSoonPage = loadable(() => import('../../pages/ComingSoonPage'));
const NewsPage = loadable(() => import('../../pages/NewsPage'));
const MemberEventPage = loadable(() => import('../../pages/MemberEventPage'));
const AssociationPage = loadable(() => import('../../pages/AssociationPage'));
const AssocLandingPage = loadable(() => import('../../pages/AssocLandingPage'));
const AssocListingPage = loadable(() => import('../../pages/AssocListingPage'));
const DirectoryPage = loadable(() => import('../../pages/DirectoryPage'));
const HeadListPage = loadable(() => import('../../pages/HeadListPage'));
const AssocSummaryPage = loadable(() => import('../../pages/AssocSummaryPage'));
const SearchingPage = loadable(() => import('../../pages/SearchingPage'));

// Config Top Application, include languages
const TopApp = loadable(() => import('../../widgets/TopApp'));

const history = createBrowserHistory();

const App = ({ ...props }) => {
  const { siteConfig } = props;
  const { data = {} } = siteConfig || {};
  const { availableLanguages, defaultLanguage, enableSSO, under_construction } = data;
  const isUnderConstruction = under_construction === 'true' || under_construction === true;

  useEffectOnce(() => {
    store.dispatch(getSiteConfig());
  });

  return (
    <>
      <CookieConsent />
      {enableSSO && <SSOStatus />}
      {availableLanguages && (
        <Router history={history} basename={c.routePathTo}>
          <Route
            path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})?`}
            component={TopApp}
          />
          <Main
            availableLanguages={localeList(availableLanguages)}
            isUnderConstruction={isUnderConstruction}
          >
            <Switch>
              {isUnderConstruction && <Route component={ComingSoonPage} />}
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})?`}
                component={HomePage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/hkforum/:years`}
                render={(props) => (
                  <Redirect
                    to={`${c.routePathTo}/${props.match.params.locale}/s/forum_hk_${props.match.params.years}`}
                  />
                )}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/hkforum/:years/(awards|awardswinner)/:pageName`}
                render={(props) => (
                  <Redirect
                    to={`${c.routePathTo}/${props.match.params.locale}/s/awards_${props.match.params.pageName}_${props.match.params.years}`}
                  />
                )}
              />
              <Route
                exact
                path={`${c.routePathTo}/hkforum/:years`}
                render={(props) => (
                  <Redirect
                    to={`${c.routePathTo}/${defaultLanguage}/s/forum_hk_${props.match.params.years}`}
                  />
                )}
              />
              <Route
                exact
                path={`${c.routePathTo}/hkforum/:years/(awards|awardswinner)/:pageName`}
                render={(props) => (
                  <Redirect
                    to={`${c.routePathTo}/${defaultLanguage}/s/awards_${props.match.params.pageName}_${props.match.params.years}`}
                  />
                )}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/search/:keyword/:curPage?`}
                component={SearchingPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/s/search`}
                render={(props) => <Redirect to={`${c.routePathTo}/${defaultLanguage}`} />}
              />
              <Route
                exact
                path={`${c.routePathTo}/search`}
                render={(props) => <Redirect to={`${c.routePathTo}/${defaultLanguage}`} />}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/search`}
                render={(props) => (
                  <Redirect to={`${c.routePathTo}/${props.match.params.locale}`} />
                )}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/search`}
                render={(props) => (
                  <Redirect to={`${c.routePathTo}/${props.match.params.locale}`} />
                )}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/photo/:pageName/:id?`}
                component={PhotoGalleryPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/video/:pageName/:id?`}
                component={VideoGalleryPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/speaker/:pageName/:id?`}
                component={SpeakersPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/programme/:pageName`}
                component={ProgrammePage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/i/:pageName/:curPage?`}
                component={IndexPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/l/:pageName`}
                component={LinksPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/press-release/:pageName`}
                component={PressReleasePage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/promotion/:pageName`}
                component={PromotionPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/news`}
                component={NewsPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/about/member/event`}
                component={MemberEventPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/about/member/associationhead`}
                component={HeadListPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/about/member/directory`}
                component={DirectoryPage}
              />

              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/about/member/list`}
                component={AssocSummaryPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/about/member/detail/:type?`}
                component={AssociationPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/about/member`}
                component={AssocLandingPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/regionalForum`}
                component={ForumPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/about/executive`}
                component={SpeakersPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/about/executive/:pageName`}
                component={SpeakersPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/about/:pageName`}
                component={StaticPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/market-intelligence/reports/:section`}
                component={ReportPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/market-intelligence`}
                component={MarketIntelligencePage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/market-intelligence/:pageName`}
                component={MarketIntelligencePage}
              />

              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/membership-benefits`}
                component={MembershipBenefitsPage}
              />

              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(
                  availableLanguages,
                )})/about/member/:type`}
                component={AssocListingPage}
              />

              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/s/:pageName`}
                component={StaticPage}
              />
              <Route
                exact
                path={`${c.routePathTo}/:path`}
                render={(props) => (
                  <Redirect to={`${c.routePathTo}/${defaultLanguage}/${props.match.params.path}`} />
                )}
              />
              <Route
                exact
                path={`${c.routePathTo}/about/:path`}
                render={(props) => (
                  <Redirect
                    to={`${c.routePathTo}/${defaultLanguage}/about/${props.match.params.path}`}
                  />
                )}
              />
              <Route
                exact
                path={`${c.routePathTo}/:section/:path/:page/:associd?`}
                render={(props) => (
                  <Redirect
                    to={
                      props.match.params.associd
                        ? `${c.routePathTo}/${defaultLanguage}/${props.match.params.section}/${props.match.params.path}/${props.match.params.page}/${props.match.params.associd}`
                        : `${c.routePathTo}/${defaultLanguage}/${props.match.params.section}/${props.match.params.path}/${props.match.params.page}`
                    }
                  />
                )}
              />
              <Route
                exact
                path={`${c.routePathTo}/:locale(${localeList(availableLanguages)})/:pageName`}
                component={StaticPage}
              />
              <Route component={NoMatch} />
            </Switch>
          </Main>
        </Router>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  siteConfig: state.siteConfig,
});

const connectStore = connect(mapStateToProps, null, null, {
  pure: true,
})(App);

export { App as AppComponent }; // <- For component Unit Test
export default connectStore;
