/* eslint-disable no-else-return */
import moment from 'moment-timezone';
import c from '../config';

const CMSAPI = (
  prefixPath,
  fileName,
  dcrType,
  projectName = c.cmsProjectName,
  enableDate = true,
  dateTime = new Date().getTime(),
) => {
  const encodedFileName = encodeURIComponent(fileName);
  if (c.subDirectoryPath.includes('ARCHIVED')) {
    const typePath = c.pageTypeArchivePathMap[dcrType] || '';
    return `${c.routePathTo}/data/${typePath}${encodedFileName}.json`;
  }

  const p = [];
  const q = [];

  p.push(`project=${c.projectNamePath + encodeURIComponent(projectName)}`);
  q.push(`Path:/${encodedFileName}.xml`);

  if (c.subDirectoryPath.includes('preview')) {
    const previewDcrType = dcrType.replaceAll(' ', '\\ ');
    q.push(`TeamSite/Templating/DCR/Type:="${previewDcrType}"`);

    if (enableDate) {
      q.push(`(onlineTime:<=${dateTime}) OR onlineTime:NaN`);
      q.push(`(offlineTime:>=${dateTime}) OR offlineTime:NaN`);
    }
    p.push(`q=(${q.map((v) => `(${v})`).join(' AND ')})`);
    p.push(`format=json`);
    p.push(`start=0`);
    p.push(`includeDCRContent=true`);
    p.push(`context=${c.apiContext.replace('%%PROJECTNAME%%', projectName)}`);
    return `${prefixPath}?${p.join('&')}`;
  }

  q.push(`TeamSite/Templating/DCR/Type:="${dcrType}"`);
  p.push(`q=(${q.map((v) => `(${v})`).join(' AND ')})`);

  if (enableDate) {
    p.push('dateCondition=true');
    // 1 hour cache
    p.push(`t=${moment(dateTime).startOf('hour').toDate().getTime()}`);
  }
  return `${prefixPath}/s/?${p.join('&')}`;
};

const CMSAPI_INDEX = (obj) => {
  const {
    sortBy = '',
    prefixPath,
    q,
    start = 0,
    max = c.itemsPerPage,
    projectName = c.cmsProjectName,
    enableDate = true,
    dateTime = new Date().getTime(),
  } = obj;
  if (c.subDirectoryPath.includes('ARCHIVED')) {
    let typePath = '';
    let jsonFile = '';
    if (q.includes('TeamSite/Templating/DCR/Type:="Announcement"')) {
      typePath = 'announcement/';
      jsonFile = 'announcement.json';
    } else if (q.includes('TeamSite/Metadata/contentCategoryValue:analysis-and-news')) {
      typePath = 'main/';
      jsonFile = 'home.json';
    } else if (q.includes('TeamSite/Metadata/Keywords:')) {
      typePath = 'index/';
      jsonFile = `${q.replace('TeamSite/Metadata/Keywords:', '')}_${start}-${max}.json`;
    }
    return `${c.routePathTo}/data/${typePath}${jsonFile}`;
  }
  const p = [];
  p.push(`start=${start}`);
  p.push(`max=${max}`);
  p.push(`project=${c.projectNamePath + encodeURIComponent(projectName)}`);

  if (c.subDirectoryPath.includes('preview')) {
    if (enableDate) {
      const query = q.replaceAll(' ', '\\ ');
      p.push(
        `q=((${query} AND ((onlineTime:<=${dateTime}) OR onlineTime:NaN) AND ((offlineTime:>=${dateTime}) OR offlineTime:NaN)))`,
      );
    } else {
      p.push(`q=(${q})`);
    }
    p.push(`format=json`);
    if (sortBy !== '' && sortBy !== 'creationDate') {
      p.push(`sort=${sortBy}`);
    }
    p.push(`includeDCRContent=true`);
    p.push(`context=${c.apiContext.replace('%%PROJECTNAME%%', projectName)}`);
    return `${prefixPath}?${p.join('&')}`;
  }

  p.push(`q=(${q})`);
  if (enableDate && projectName !== 'RMIP') {
    p.push('dateCondition=true');
    // 1 hour cache
    p.push(`t=${moment(dateTime).startOf('hour').toDate().getTime()}`);
  }

  if (sortBy !== '' && sortBy !== 'creationDate') {
    p.push(`sort=${sortBy}`);
  }

  return `${prefixPath}/i/?${p.join('&')}`;
};

export { CMSAPI, CMSAPI_INDEX };
