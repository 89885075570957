// All reducers event not under Containers folder
import { combineReducers } from 'redux';
import { i18nReducer } from 'react-redux-i18n';
// Other reducers
import siteConfig from './siteConfig/reducer';
import generalPage from './generalPage/reducer';
import indexPage from './indexPage/reducer';
import indexPageList from './indexPageList/reducer';
import allowCookies from './allowCookies/reducer';
import enableTealium from './enableTealium/reducer';
import enableAdGlare from './enableAdGlare/reducer';
import announcement from './announcement/reducer';
import widgetArticleList from './widgetArticleList/reducer';
import enableSSO from './enableSSO/reducer';
import sbeProgramme from './sbeProgramme/reducer';
import sbeSpeaker from './sbeSpeaker/reducer';
import hkmbArticles from './hkmbArticles/reducer';

export default combineReducers({
  i18n: i18nReducer,
  siteConfig,
  generalPage,
  indexPage,
  indexPageList,
  allowCookies,
  enableTealium,
  enableAdGlare,
  announcement,
  widgetArticleList,
  enableSSO,
  sbeProgramme,
  sbeSpeaker,
  hkmbArticles,
});
