export default {
  lscsPath: 'https://api-cms-sit.hktdc.com/ls/v2',
  projectNamePath: '%2F%2Fcmsapp%2F',
  apiContext: '',
  subDirectoryPath: 'ARCHIVED',
  routePathTo: `/${process.env.REACT_APP_TSPROJECTNAME}/2019`,
  locationServiceEndPoint: 'https://api-general-uat.hktdc.com/location/v1/location-service',
  footer_js_env: '-sit',
  autoCompletePath: 'https://api-fair.hktdc.com/fair-core/v1/auto-complete',
  eventPath: 'https://event.hktdc.com',
  researchDomain: 'https://research-sit.hktdc.com',
  sbeProgramePath: 'https://api-cms.hktdc.com/sbe/v1/seminar/programme',
  sbeSpeakerPath: 'https://api-cms.hktdc.com/sbe/v1/seminar/speakers',
  searchAPIPath: 'https://api-federation-sit.hktdc.com/federation/v1/federations',
};
