import { createReducer } from '@reduxjs/toolkit';
import DOMPurify from 'dompurify';
import parse from 'html-react-parser';
import truncate from 'truncate-html';
import moment from 'moment-timezone';
import { isObject, isArray } from 'lodash';
import config from '../config';

const isSSOLoggedIn = async () => {
  if (window.ssoClient) {
    return (await window.ssoClient.getAccessToken()) || false;
  }
  return false;
};

const isExternalURL = (str) => {
  const regex = /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
  if (!regex.test(str)) {
    return false;
  }
  return true;
};

const constructPath = (routeTo, lang, pageType, pageName) => {
  return `${routeTo}/${lang}/${pageType}/${pageName}`;
};

const newEle = (id, src, target, tag = 'script', type = '', async = true) => {
  let js = document.getElementById(id);
  if (js) {
    js.parentNode.removeChild(js); // don't use .remove() when IE doesn't work
  }
  if (tag === 'stylesheet') {
    const jsPreloadId = `${id}PreLoad`;
    let jsPreload = document.getElementById(jsPreloadId);
    if (jsPreload) {
      jsPreload.parentNode.removeChild(jsPreload); // don't use .remove() when IE doesn't work
    }

    jsPreload = document.createElement('link');
    jsPreload.setAttribute('href', src);
    jsPreload.setAttribute('rel', 'preload');
    jsPreload.setAttribute('as', 'style');
    jsPreload.setAttribute('id', jsPreloadId);
    jsPreload.async = async;
    if (type !== '') jsPreload.setAttribute('type', type);
    target.appendChild(jsPreload);

    js = document.createElement('link');
    js.setAttribute('href', src);
    js.setAttribute('rel', 'stylesheet');
  } else {
    js = document.createElement(tag);
    js.setAttribute('src', src);
    js.setAttribute('charset', 'UTF-8');
  }
  js.async = async;
  js.setAttribute('id', id);
  if (type !== '') js.setAttribute('type', type);
  target.appendChild(js);
};

const is_Preview = () => {
  const stage = process.env.REACT_APP_STAGE || '';
  return stage.includes('preview');
};

const defaultReducerSchema = (
  types,
  initialState = {
    isLoading: false,
    data: [],
    error: false,
  },
) => {
  return createReducer(initialState, {
    [types.ACTION_RESET]: () => ({
      ...initialState,
    }),
    [types.ACTION_ERROR]: (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
      error: true,
    }),
    [types.ACTION_SUCCESS]: (state, action) => ({
      ...state,
      isLoading: false,
      data: action.payload,
      error: false,
    }),
    [types.ACTION_REQUEST]: (state, action) => ({
      ...state,
      isLoading: true,
      ...(action.payload && { params: action.payload }),
      error: false,
    }),
  });
};

const sanitizedHTML = (html) => {
  return DOMPurify.sanitize(html, {
    ALLOWED_TAGS: config.allowedTags,
    ALLOWED_ATTR: config.allowedAttributes,
  });
};

const parseSanitizedHTML = (html, length) => {
  if (length) {
    return parse(sanitizedHTML(truncate(html, length)));
  }
  return parse(sanitizedHTML(html));
};

const currentDate = () => {
  const previewDate = new URLSearchParams(window.location.search).get('previewDate') || null;
  return previewDate ? moment(previewDate).toDate() : moment().toDate();
};

// Parsing Date from CMS, most likely using UTC Datetime such as 2018-01-01 12:00:00
const momentUTCDate = (dateString) => {
  return moment.tz(dateString, 'UTC').toDate();
};

const isDateBetweenDateRange = (minDateString, maxDateString, currentDateString) => {
  const compareDate = moment(currentDateString).toDate();
  const minDate = momentUTCDate(minDateString);
  const maxDate = momentUTCDate(maxDateString);
  return compareDate >= minDate && compareDate <= maxDate;
};

const forceToArray = (data) => {
  if (isArray(data)) {
    return data;
  }
  if (isObject(data) && !Object.values(data).every((x) => x === null || x === '')) {
    return [data];
  }
  return null;
};

const localeList = (languages) => {
  return languages
    .split(',')
    .map((v) => v.trim())
    .join('|');
};

const fireTealiumEvent = (tealiumData) => {
  const fireTealiumAction = () => {
    window.utag.link(JSON.parse(tealiumData));
    console.log(JSON.parse(tealiumData));
  };

  const fireTealium = () => {
    if (window.utag) {
      fireTealiumAction();
    } else {
      console.log('====================================');
      console.log('window utag not ready');
      console.log('====================================');

      let tryCount = 0;
      const waitTealiumLibraryInit = setInterval(() => {
        console.log('waitTealiumLibraryInit.....');
        tryCount++;
        if (window.utag) {
          fireTealiumAction();
          tryCount = 0;
          clearInterval(waitTealiumLibraryInit);
        }
        if (tryCount > config.interval.times) {
          console.log(`TealiumLibrary init Fail within ${config.interval.times} try`);
          tryCount = 0;
          clearInterval(waitTealiumLibraryInit);
        }
      }, config.interval.frequency);
    }
  };

  fireTealium();
};

export {
  isExternalURL,
  constructPath,
  newEle,
  is_Preview,
  defaultReducerSchema,
  sanitizedHTML,
  parseSanitizedHTML,
  currentDate,
  momentUTCDate,
  isDateBetweenDateRange,
  forceToArray,
  localeList,
  fireTealiumEvent,
  isSSOLoggedIn,
};
